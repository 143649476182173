
.App {
  text-align: center;
}

body{
  background-color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Spinner */
.spinner-wrapper{
  width:100%;
  height:100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-wrapper .spinner-border{
    height:8rem;
    width: 8rem;
    border-width: 0.3rem;
}

.landing-page-container{
  min-height:100%;
}

.small .spinner-wrapper{
  height:auto;
}
.landing-page-container .card.smaller{
  max-width:650px;
  width: 100%;
}
.details-container .row{
  justify-content: space-evenly;
}

.details-container .row div{
  text-align: left;
}
.details-container .row div p{
  text-align: right;
}

.edit-dropdown{
  width:100%;
  text-align: left;
}

.form-control.checkbox{
  border:none;
  box-shadow: none;
  height: 25px;
  text-align: left;
  width:25px;
}

.complete.progress-bar{
  background-color:#00d27a;
}

.button-no-style{
  background-color: transparent;
  border: none;
}

.button-card-header{
  font-weight: 500 !important;
  color: #344050;
}

.button-card-header.collapsed i svg,
.table-item.collapsed i svg,
.tag-list-button.collapsed i svg{
  transform: rotate(-90deg);
  transition: ease-in-out 0.1s;
}

.button-card-header i svg,
.table-item i svg,
.tag-list-button i svg{
  transform: rotate(0deg);
  transition: ease-in-out 0.1s;
}

.req:before{
  content: "*";
  color: red;
}

.form-check-input{
  margin-left:-15px;
  margin-top: 4px;
}

.justify-space-evenly{
  justify-content: space-evenly;
}

.org-logo-container img{
  max-width:250px;
  height:auto;
}

.footer-logo-container{
  position: sticky;
  bottom:0;
  width:100%;
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 15px 0;
}

.footer-logo-container img{
  height:30px;
  width:auto;
}

@media(max-width:575px){
  h3{
    font-size:1.4rem;
  }
  h5{
    font-size: 1rem;
  }
}

.small-number-plate, .number-plate{
  font-family: 'NumberPlate';
  background-color: #ffc439;
  color:#312f2f;
  text-align: center !important;
  border-radius: 3px;
  padding:3px;
  position: relative;
  transition-duration: 0.5s;
  border:#312f2f solid 2px;
}

.number-plate{
  font-size:25px;
  width:155px;
  padding-left:25px;
  min-height: 45px;
}

.small-number-plate{
  font-size:21px;
  padding-left:15px;
  width:140px;
  min-height: 35px;
}

.small-number-plate::before, .number-plate::before{
  height:105%;
  content: '';
  position:absolute;
  left:-1px;
  top:-1px;
  width:15px;
  background-color: #232f68;
}
.number-plate::before{
  width:20px;
}

.number-plate-link:hover{
  text-decoration: none;
}

.number-plate-link:hover .small-number-plate, .number-plate-link:hover .number-plate{
  transition-duration: 1s;
  transition-property: background-color, box-shadow;
  background: white;
  box-shadow: inset 0 0 3px grey;
  transition: ease-in-out 0.3s;
}

.small-number-plate.form-control, .number-plate.form-control{
  background-color: #ffc439 !important;
}

input.number-plate, input.small-number-plate{
  padding:2px;
}

input.number-plate::-webkit-input-placeholder, input.small-number-plate::-webkit-input-placeholder{
  color:#312f2f;
  opacity: 1;
}
